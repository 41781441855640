import React from 'react';
import Layout from '../components/layout';
import Page from '../components/common/layouts/page';
import img from '../images/gatsby-astronaut.png';

const ElementDesignBreakdown = () => {
	return (
		<Layout>
			<Page>
				<h2>TEXT</h2>
				<p>
					This is <b>bold</b> and this is <strong>strong</strong>. This is{' '}
					<i>italic</i> and this is <em>emphasized</em>. This is{' '}
					<sup>superscript</sup> text and this is <sub>subscript</sub> text.
					This is <u>underlined</u>. Finally, this is a <a href="#">link</a>.
				</p>
				<hr />
				<h1>HEADING LEVEL 1</h1>
				<h2>HEADING LEVEL 2</h2>
				<h3>HEADING LEVEL 3</h3>
				<h4>HEADING LEVEL 4</h4>
				<h5>HEADING LEVEL 5</h5>
				<h6>HEADING LEVEL 6</h6>
				<hr />
				<h2>Image</h2>
				{!!img && <img src={img} alt="img" />}
				<hr />
				<h2>Lists</h2>
				<h3>Unordered</h3>
				<ul>
					<li>Dolor pulvinar etiam.</li>
					<li>Sagittis lorem eleifend.</li>
					<li>Felis feugiat dolore viverra.</li>
					<li>Dolor pulvinar etiam.</li>
				</ul>
				<h3>Ordered</h3>
				<ol>
					<li>Dolor pulvinar etiam.</li>
					<li>Etiam vel felis at viverra.</li>
					<li>Felis enim feugiat magna.</li>
					<li>Etiam vel felis nullam.</li>
					<li>Felis enim et tempus.</li>
				</ol>
				<hr />
				<h2>Blockquote</h2>
				<blockquote>
					Fringilla nisl. Donec accumsan interdum nisi, quis tincidunt felis
					sagittis eget tempus euismod. Vestibulum ante ipsum primis in faucibus
					vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing
					accumsan faucibus. Vestibulum ante ipsum primis in faucibus
					vestibulum. Blandit adipiscing eu felis.
				</blockquote>
			</Page>
		</Layout>
	);
};

export default ElementDesignBreakdown;
